import Styled from "styled-components";

export const ArrowBack = Styled.div`
  font-size: 30px;
  color: ${props => props.color};
  background-color: ${props => (props.background ? "rgba(0,0,0,0.1);" : null)};
  height: ${props => (props.background ? "100%" : null)};
  padding: 0%;
  float:left;
  clear:both;
  width: auto;
  svg {
    cursor: pointer;
  }
  @media (min-width: 0px) and (max-width:1500px){
    float:none;
    display:block;
    margin-left: 20px;
  }

`;

export const FormDiv = Styled.div`
    margin-top: 1%;
`;
export const Btns = Styled.button`
    max-width: 200px !important;
    background: #363636 !important;
    color: #fff !important;
    float: right !important;
    clear: both !important;
    margin-bottom: 5% !important;
`;
export const FormSpan = Styled.span`
  font-family: Stureplansgruppen !important;
  color: #007bff;
  cursor: pointer;
`;
