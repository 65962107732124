import React, { useState } from "react"
import * as Style from "../css/formStyle"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const PolicyForm = ({ toggleForm, english }) => {
  /*  statehandler for form inputs*/
  const uForm = {}
  const [userForm, setUserForm] = useState(uForm)
  /*onchange för text input */
  const onChange = e => {
    let newState = { ...userForm }
    if (e.target.name === "relationOm") {
      newState.relation = { ...newState.relation, Annan: e.target.value }
    } else {
      newState[e.target.name] = e.target.value
    }
    setUserForm(newState)
  }

  /*onchange för checkboxes */
  const onChangeCheckbox = e => {
    let newState = { ...userForm }
    let name = e.target.value
    e.target.checked
      ? (newState[e.target.name] = {
          ...newState[e.target.name],
          [name]: e.target.checked,
        })
      : delete newState[e.target.name][name]
    setUserForm(newState)
  }
  return (
    <div>
      <Style.ArrowBack color={"black"} onClick={() => toggleForm()}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </Style.ArrowBack>
      <form className="form container" action="https://formspree.io/xknwnqgk" method="POST">
        {english ? (
          <h1>Personal Data Stureplansgruppen</h1>
        ) : (
          <h1>Personuppgifter Stureplansgruppen</h1>
        )}
        <div className="form-group">
          <label htmlFor="name">
            {english ? "1. What is your name?" : "1. Vad är ditt namn?"}
          </label>
          <input
            required="required"
            type="text"
            className="form-control"
            name="namn"
            id="name"
            value={uForm.name}
            placeholder={english ? "name" : "namn"}
            onChange={onChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="name">
            {english ? "2. What is your e-mail?" : "2. Vad är din mailadress?"}
          </label>
          <input
           required="required"
            type="email"
            name="email"
            className="form-control"
            id="email"
            value={uForm.email}
            placeholder={english ? "email" : "epostadress"}
            onChange={onChange}
          />
        </div>

        <div className="form-group">
          <div>
            <label>
              {english
                ? "3. What is your relation to Stureplansgruppen?"
                : "3. Vad är din relation till Stureplansgruppen?"}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="earlyEmployed"
              name="Vad är din relation till Stureplansgruppen?"
              value="Tidigare anställd"
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="earlyEmployed">
              {english ? "Former employee" : "Tidigare anställd"}
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="employed"
              name="Vad är din relation till Stureplansgruppen?"
              value="Nuvarande anställd"
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="employed">
              {english ? "Current employee" : "Nuvarande anställd"}
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="appliedForJob"
              name="Vad är din relation till Stureplansgruppen?"
              value="Jobbsökande"
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="appliedForJob">
              {english ? "Job applicant" : "Jobbsökande"}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="guest"
              name="Vad är din relation till Stureplansgruppen?"
              value="Gäst"
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="guest">
              {english ? "Guest" : "Gäst"}
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="supplier"
              name="Vad är din relation till Stureplansgruppen?"
              value="Leverantör"
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="supplier">
              {english ? "Supplier" : "Leverantör"}
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="client"
              name="Vad är din relation till Stureplansgruppen?"
              value="Klient"
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="client">
              {english ? "Client" : "Klient"}
            </label>
          </div>
          <br />
          <small>
            <label htmlFor="relationOm">
              {english
                ? "If other, please specify"
                : "Om annan, vänligen specificera"}
            </label>
          </small>
          <input
            type="text"
            className="form-control"
            id="relationOm"
            placeholder=""
            name="Om annan, vänligen specificera"
            onChange={onChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="question4">
            {english
              ? "4. Are you or have you been an employee at Stureplansgruppen? If yes, where and when? Otherwise please write no."
              : "4. Har du varit anställd inom Stureplansgruppen? Om ja, var och när? Annars skriv nej."}
          </label>
          <input
            id="question4"
            type="text"
            className="form-control"
            placeholder=""
            name="Har du varit anställd inom Stureplansgruppen? Om ja, var och när? Annars skriv nej"
            value={uForm["Har du varit anställd inom Stureplansgruppen? Om ja, var och när? Annars skriv nej"]}
            onChange={onChange}
          ></input>
        </div>
        <div className="form-group">
          <label htmlFor="question5">
            {english
              ? "5. Have you stayed at Stureplansgruppen’s hotels? If yes, where and when? Otherwise please write no."
              : "5. Har du bott på något av Stureplansgruppens hotell? Om ja, var och när? Annars skriv nej."}
          </label>
          <input
            id="question5"
            type="text"
            className="form-control"
            placeholder=""
            name="Har du bott på något av Stureplansgruppens hotell? Om ja, var och när? Annars skriv nej"
            value={uForm["Har du bott på något av Stureplansgruppens hotell? Om ja, var och när? Annars skriv nej"]}
            onChange={onChange}
          ></input>
        </div>
        <div className="form-group">
          <label htmlFor="question6">
            {english
              ? " 6. Have you made any reservations at Stureplansgruppen’s restaurants? If yes, where and when? Otherwise, please write no."
              : "6. Har du gjort bokningar på Stureplansgruppens restauranger? Om ja, var och när? Annars skriv nej."}
          </label>
          <input
            id="question6"
            type="text"
            className="form-control"
            name="Har du gjort bokningar på Stureplansgruppens restauranger? Om ja, var och när? Annars skriv nej"
            value={uForm["Har du gjort bokningar på Stureplansgruppens restauranger? Om ja, var och när? Annars skriv nej"]}
            onChange={onChange}
            placeholder=""
          ></input>
        </div>
        <div className="form-group">
          <label htmlFor="question7">
            {english
              ? "7. Have you entered into any contracts with Stureplansgruppen? If yes, with which company and when? Who were you in touch with? Otherwise please write no."
              : "7. Har du ingått avtal med Stureplansgruppen? Om ja, med vilket bolag och när? Vem hade du kontakt med? Annars skriv nej."}
          </label>
          <input
            id="question7"
            type="text"
            className="form-control"
            placeholder=""
            name="Har du ingått avtal med Stureplansgruppen? Om ja, med vilket bolag och när? Vem hade du kontakt med? Annars skriv nej"
            value={uForm["Har du ingått avtal med Stureplansgruppen? Om ja, med vilket bolag och när? Vem hade du kontakt med? Annars skriv nej"]}
            onChange={onChange}
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="question8">
            {english
              ? "8. Have you had any e-mail contact with anyone at Stureplansgruppen? If yes, with who and when? Otherwise please write no."
              : "8. Har du haft mailkontakt med någon på Stureplansgruppen? Om ja, med vem och när? Annars skriv nej."}
          </label>
          <input
            id="question8"
            type="text"
            className="form-control"
            placeholder=""
            name="Har du haft mailkontakt med någon på Stureplansgruppen? Om ja, med vem och när? Annars skriv nej"
            value={uForm["Har du haft mailkontakt med någon på Stureplansgruppen? Om ja, med vem och när? Annars skriv nej"]}
            onChange={onChange}
          ></input>
        </div>

        <div className="form-group">
          <label>
            {english
              ? "9. Have you used any of Stureplansgruppen’s applications?"
              : "9. Har du använt någon av Stureplansgruppens appar?"}
          </label>
          <br />
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="Avenygruppen"
              name="Har du använt någon av Stureplansgruppens appar?"
              value="Avenygruppen"
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="Avenygruppen">
              Avenygruppen
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="Stureplan"
              name="Har du använt någon av Stureplansgruppens appar?"
              value="Stureplan"
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="Stureplan">
              Stureplan
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="SPGStaff"
              name="Har du använt någon av Stureplansgruppens appar?"
              value="SPGStaff"
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="SPGStaff">
              SPGStaff
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              id="Ingen"
              name="Har du använt någon av Stureplansgruppens appar?"
              value={"Ingen"}
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="Ingen">
              {english ? "None of the above" : "Ingen"}
            </label>
          </div>
        </div>

        <div className="form-group">
          <div>
            <label>
              {english
                ? "10. Is this request directed to a particular company or a particular event? If yes, which?"
                : "10. Riktar sig förfrågan mot ett specifikt bolag eller en specifik händelse?"}
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="yesCompany"
              name="Riktar sig förfrågan mot ett specifikt bolag eller en specifik händelse?"
              value="Ja"
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="yesCompany">
              {english ? "Yes" : "Ja"}
            </label>
          </div>

          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              id="noCompany"
              name="Riktar sig förfrågan mot ett specifikt bolag eller en specifik händelse?"
              value="Nej"
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="noCompany">
              {english ? "No" : "Nej"}
            </label>
          </div>

          <br />
          <small>
            <label htmlFor="yesToCompany">
              {english ? "  If yes, which?" : "Om ja, vilket/vilken?"}
            </label>
          </small>
          <input
            id="yesToCompany"
            type="text"
            className="form-control"
            placeholder=""
            name="Om ja, vilket/vilken?"
            value={uForm["Om ja, vilket/vilken?"]}
            onChange={onChange}
          ></input>
        </div>

        <div className="form-group">
          <label htmlFor="question11">
            {english
              ? "11. Is there any other information which could be of importance for the request? Otherwise please write no."
              : "11. Finns det någon annan information som skulle kunna vara av betydelse för förfrågan? Annars skriv nej."}
          </label>
          <input
            id="question11"
            type="text"
            className="form-control"
            placeholder=""
            name="Finns det någon annan information som skulle kunna vara av betydelse för förfrågan? Annars skriv nej"
            value={uForm["Finns det någon annan information som skulle kunna vara av betydelse för förfrågan? Annars skriv nej"]}
            onChange={onChange}
          ></input>
        </div>

        <div className="form-group">
          <div>
            <label>
              {english
                ? "12. Which measures would you want be taken with your personal data?"
                : "12. Vilka åtgärder vill du ska vidtas med dina personuppgifter?"}
            </label>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="yesCompany"
              value="Ja"
              onChange={onChangeCheckbox}
              name="Jag vill se mina personuppgifter och information om hur de behandlas"
            />
            <label className="form-check-label" htmlFor="yesCompany">
              {english
                ? "I want to access my personal data and information regarding how they are processed"
                : "Jag vill se mina personuppgifter och information om hur de behandlas"}
            </label>
          </div>

          <div className="form-check">
            <br />
            <input
              className="form-check-input"
              type="checkbox"
              id="yesCompany"
              value="ja"
              onChange={onChangeCheckbox}
            />
            <label className="form-check-label" htmlFor="yesCompany">
              {english
                ? "I want my personal data rectified. If yes, which data and why?"
                : "Jag vill att mina personuppgifter rättas. Om ja, vilka uppgifter och varför?"}
            </label>
            <input
              id="question11"
              type="text"
              className="form-control"
              name="Jag vill att mina personuppgifter rättas. Om ja, vilka uppgifter och varför?"
              value={uForm["Jag vill att mina personuppgifter rättas. Om ja, vilka uppgifter och varför?"]}
              onChange={onChange}
            ></input>
          </div>

          <div className="form-check">
            <br />
            <input
              className="form-check-input"
              type="checkbox"
              id="yesCompany"
              value="option2"
            />
            <label className="form-check-label" htmlFor="yesCompany">
              {english
                ? "I want my personal data deleted. If yes, which data and why?"
                : "Jag vill att mina personuppgifter tas bort. Om ja, vilka uppgifter och varför?"}
            </label>
            <input
              id="yesCompany"
              type="text"
              className="form-control"
              placeholder=""
              name="Jag vill att mina personuppgifter tas bort. Om ja, vilka uppgifter och varför?"
              value={uForm["Jag vill att mina personuppgifter tas bort. Om ja, vilka uppgifter och varför?"]}
              onChange={onChange}
            ></input>
          </div>

          <div className="form-check">
            <br />
            <input
              className="form-check-input"
              type="checkbox"
              id="yesCompany"
              value="option2"
            />
            <label className="form-check-label" htmlFor="yesCompany">
              {english
                ? "want to restrict the processing of my personal data. If yes, which data and why?"
                : "Jag vill begränsa behandlingen av mina personuppgifter. Om ja, vilka uppgifter och varför?"}
            </label>
            <input
              id="question11"
              type="text"
              className="form-control"
              placeholder=""
              name="Jag vill begränsa behandlingen av mina personuppgifter. Om ja, vilka uppgifter och varför?"
              value={uForm["Jag vill begränsa behandlingen av mina personuppgifter. Om ja, vilka uppgifter och varför?"]}
              onChange={onChange}
            ></input>
          </div>

          <div className="form-check">
            <br />
            <input
              className="form-check-input"
              type="checkbox"
              id="yesCompany"
              value="option2"
            />
            <label className="form-check-label" htmlFor="yesCompany">
              {english
                ? "I want the processing of my personal data to stop. If yes, which data and why?"
                : "Jag vill att behandlingen av mina personuppgifter upphör. Om ja, vilka uppgifter och varför?"}
            </label>
            <input
              id="question11"
              type="text"
              className="form-control"
              placeholder=""
              name="Jag vill att behandlingen av mina personuppgifter upphör. Om ja, vilka uppgifter och varför?"
              value={uForm["Jag vill att behandlingen av mina personuppgifter upphör. Om ja, vilka uppgifter och varför?"]}
              onChange={onChange}
            ></input>
          </div>

          <div className="form-check">
            <br />
            <input
              className="form-check-input"
              type="checkbox"
              id="yesCompany"
              value="option2"
            />
            <label className="form-check-label" htmlFor="yesCompany">
              {english
                ? " I want my personal data transferred. If yes, which data and why?"
                : "Jag vill få mina personuppgifter flyttade. Om ja, vilka uppgifter och varför?"}
            </label>
            <input
              id="question11"
              type="text"
              className="form-control"
              placeholder=""
              name="Jag vill få mina personuppgifter flyttade. Om ja, vilka uppgifter och varför?"
              value={uForm["Jag vill få mina personuppgifter flyttade. Om ja, vilka uppgifter och varför?"]}
              onChange={onChange}
            ></input>
          </div>
        </div>
        <div className="form-group">
          <Style.Btns className="btn col-sm-6 col-md-12" type="submit">
            {english ? "Send" : "Skicka"}
          </Style.Btns>
        </div>
      </form>
    </div>
  )
}

export default PolicyForm
