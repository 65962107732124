import React, { useState } from "react";
import { useGlobal } from "reactn";
import SEO from "../../components/seo";
import * as Styles from "../../css/policiesStyles";
import "../../css/global.css";
import * as Styled from "../../css/formStyle";
import * as Global from "../../css/globalStyle";
import Policy from "./policy";
import App from "../app";
import { useStaticQuery, graphql } from "gatsby";
import PolicyForm from "../../components/policyForm";
import getLang from "../../components/scripts/getLang";
import BackgroundImage from "gatsby-background-image";

const Policies = () => {
  let [english] = useGlobal("English");
  const data = useStaticQuery(
    graphql`
      query {
        allContentfulPolicies {
          edges {
            node {
              seoTitel
              seoDescription
              node_locale
              heroRubrik
              heroBild {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
              childContentfulPoliciesCookiepolicyRichTextNode {
                childContentfulRichText {
                  html
                }
              }
              childContentfulPoliciesIntegritetspolicyRichTextNode {
                childContentfulRichText {
                  html
                }
              }
              childContentfulPoliciesStureplansgruppensApparRichTextNode {
                childContentfulRichText {
                  html
                }
              }
              childContentfulPoliciesKamerabevakningspolicyRichTextNode {
                childContentfulRichText {
                  html
                }
              }
            }
          }
        }
      }
    `
  );
  let policyData = getLang(english, data.allContentfulPolicies);
  let {
    childContentfulPoliciesCookiepolicyRichTextNode,
    childContentfulPoliciesIntegritetspolicyRichTextNode,
    childContentfulPoliciesStureplansgruppensApparRichTextNode,
    childContentfulPoliciesKamerabevakningspolicyRichTextNode,
    seoTitel,
    heroBild,
    heroRubrik,
    seoDescription
  } = policyData[0].node;

  const policyDB = [
    {
      name: english
        ? "Stureplansgruppen’s Privacy Policy "
        : "Stureplansgruppens integritetspolicy",
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html:
              childContentfulPoliciesIntegritetspolicyRichTextNode
                .childContentfulRichText.html
          }}
        />
      )
    },
    {
      name: english
        ? "Stureplansgruppen’s Cookie Policy"
        : "Stureplansgruppens cookiepolicy",
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html:
              childContentfulPoliciesCookiepolicyRichTextNode
                .childContentfulRichText.html
          }}
        />
      )
    },
    english
      ? null
      : {
          name: "Användarvillkor Stureplansgruppens appar",
          content: (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  childContentfulPoliciesStureplansgruppensApparRichTextNode
                    .childContentfulRichText.html
              }}
            />
          )
        },
    english
      ? null
      : {
          name: "Stureplansgruppens Kamerabevakningspolicy ",
          content: (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  childContentfulPoliciesKamerabevakningspolicyRichTextNode
                    .childContentfulRichText.html
              }}
            />
          )
        }
  ];
  let [formValue, setFormView] = useState(false);

  const toggleForm = () => {
    setFormView(!formValue);
  };

  let PreRender = (english, policyDB) => {
    if (formValue) {
      return <PolicyForm toggleForm={toggleForm} english={english} />;
    } else {
      return (
        <div>
          {english ? (
            <p>
              You can find more information below about Stureplansgruppen's
              policies and terms of use.
            </p>
          ) : (
            <p>
              Nedan finner du Stureplansgruppens policyer och användarvillkor.
            </p>
          )}
          {policyDB.map((policy, index) => {
            if (policy === null) {
              return null;
            }
            return (
              <Policy
                key={index}
                name={policy.name}
                content={policy.content}
              ></Policy>
            );
          })}

          <Styled.FormDiv>
            {english ? (
              <p>
                To access your personal information please use our{" "}
                <Styled.FormSpan onClick={() => toggleForm()}>
                  form
                </Styled.FormSpan>
              </p>
            ) : (
              <p>
                För tillgång till dina personuppgifter vänligen använd vårt{" "}
                <Styled.FormSpan onClick={() => toggleForm()}>
                  formulär
                </Styled.FormSpan>
                .
              </p>
            )}
            {english ? (
              <p>
                For other questions please contact us{" "}
                <a href="mailto:data@stureplansgruppen.se">
                  data@stureplansgruppen.se
                </a>
              </p>
            ) : (
              <p>
                För andra frågor vänligen hör av er till{" "}
                <a href="mailto:data@stureplansgruppen.se">
                  data@stureplansgruppen.se
                </a>
              </p>
            )}
          </Styled.FormDiv>
        </div>
      );
    }
  };
  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      <BackgroundImage
        Tag="section"
        className={"hero"}
        fluid={heroBild.fluid}
        backgroundColor={`#040e18`}
      >
        <Global.HeroText >
          <h1> {heroRubrik} </h1>
        </Global.HeroText>
      </BackgroundImage>

      <Styles.PoliciesWrapper>
        {PreRender(english, policyDB)}
      </Styles.PoliciesWrapper>
    </App>
  );
};

export default Policies;
